import React, { FC } from 'react'
import Helmet from 'react-helmet'

type Props = {
	title: string
	description: string
	url: string
	type?: string
	image?: string
	lang?: string
	robots?: string
}

// descriptionからhtmlタグを除去
// 120文字に要約
export const summarize = (html: string, maxLength = 120): string => {
	const TagWithoutString = html.replace(/(<([^>]+)>)/gi, "").trim()
	return TagWithoutString.length > maxLength ? TagWithoutString.slice(0, maxLength) + '...' : TagWithoutString
}

const SEO: FC<Props> = ({
		title,
		description,
		url,
		image = 'https://images.microcms-assets.io/assets/4a410f4032b948dd82cfbd2261ae3f6d/2798315adf6e409f91df8fef02da3c4e/ogp.jpg',
		type = 'website',
		lang = 'ja',
		robots = 'all',
		children,
	}) => {

	const seo = {
		title: title,
		description: description,
		image: image,
		url: url,
		siteName: 'Onsen*',
		type: type,
		lang: lang,
		robots: robots,
		canonical: url,
	}

	return (
		<Helmet>
			<title>{seo.title}</title>
			<html lang={seo.lang} />
			<meta name="robots" content={seo.robots} />
			<meta name='description' content={seo.description} />
			<link rel='canonical' href={seo.url} />
			<meta name='image' content={seo.image} />
			<meta property='og:url' content={seo.url} />
			<meta property='og:type' content={seo.type} />
			<meta property='og:title' content={seo.title} />
			<meta property="og:site_name" content={seo.siteName} />
			<meta property='og:description' content={seo.description} />
			<meta property='og:image' content={seo.image} />
			<meta property='twitter:site' content='@onsen_cure' />
			<meta property='twitter:card' content='summary_large_image' />
			<meta property='twitter:title' content={seo.title} />
			<meta property='twitter:description' content={seo.description} />
			{children}
			<script src="https://connect.facebook.net/signals/config/322516088958831?v=2.9.45&amp;r=stable" async />
		</Helmet>
	)
}

export default SEO